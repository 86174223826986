<template lang="pug">
  main#main.oauth-callback.d-flex.flex-grow-1.align-items-center.justify-content-center
    wc-loaders-cube
</template>

<script>
import WcLoadersCube from '@components/shared/loaders/WcLoadersCube'

export default {
  name: 'oauth-callback-silent',
  components: {
    WcLoadersCube,
  },
  mounted() {
    this.$auth.signInCallbackSilent().catch((error) => {
      throw error
    })
  },
}
</script>
